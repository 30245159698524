<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add Courier Location Mappings</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form class="ma-0 pa-0 row">
          <div class="col-12 col-sm-12 col-md-4 py-0 my-0">
            <v-text-field
              v-model="formData.carrier_value"
              label="Carrier value"
              clearable
              outlined
              dense
              :error-messages="carrier_valueErrors"
              @input="$v.formData.carrier_value.$touch()"
              @blur="$v.formData.carrier_value.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 col-md-4 py-0 my-0">
            <v-select
              v-model="formData.carrier_id"
              label="Carrier"
              item-text="text"
              item-value="index"
              :items="serverData.carriers"
              clearable
              outlined
              dense
              :error-messages="carrier_idErrors"
              @input="$v.formData.carrier_id.$touch()"
              @blur="$v.formData.carrier_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 col-md-4 py-0 my-0">
            <v-select
              v-model="formData.mapping_type"
              label="Mapping type"
              item-text="text"
              item-value="index"
              :items="serverData.types"
              clearable
              outlined
              dense
              :error-messages="mapping_typeErrors"
              @input="$v.formData.mapping_type.$touch()"
              @blur="$v.formData.mapping_type.$touch()"
            ></v-select>
          </div>

          <!-- address -->
          <input-group label="Location">
            <div class="col-6 col-sm-6 py-0 my-0">
              <v-autocomplete
                @change="onCountryChange"
                v-model="$v.formData.country.$model"
                :disabled="
                  !(formData.mapping_type === 0 || formData.mapping_type > 0)
                "
                label="Country"
                item-text="text"
                item-value="index"
                :items="serverData.countries"
                clearable
                outlined
                dense
                :error-messages="countryErrors"
                @input="$v.formData.country.$touch()"
                @blur="$v.formData.country.$touch()"
              ></v-autocomplete>
            </div>
            <div class="col-6 col-sm-6 py-0 my-0">
              <v-autocomplete
                v-model="$v.formData.state.$model"
                :items="serverData.states"
                :disabled="!(isStatesLoaded && formData.mapping_type >= 1)"
                item-text="title"
                item-value="id"
                label="State"
                persistent-hint
                outlined
                dense
                :error-messages="stateErrors"
                @input="$v.formData.state.$touch()"
                @blur="$v.formData.state.$touch()"
                @change="onStateChange"
              >
              </v-autocomplete>
            </div>
            <div class="col-6 col-sm-6 py-0 my-0">
              <v-autocomplete
                v-model="$v.formData.city.$model"
                :items="serverData.cities"
                :disabled="!(isCitiesLoaded && formData.mapping_type >= 2)"
                item-text="title"
                item-value="id"
                label="City"
                persistent-hint
                outlined
                dense
                :error-messages="cityErrors"
                @input="$v.formData.city.$touch()"
                @blur="$v.formData.city.$touch()"
                @change="onCityChange"
              >
              </v-autocomplete>
            </div>
            <div class="col-6 col-sm-6 py-0 my-0">
              <v-autocomplete
                :disabled="!(isAreasLoaded && formData.mapping_type >= 3)"
                v-model="formData.area"
                :items="serverData.areas"
                item-text="title"
                item-value="id"
                label="Area"
                persistent-hint
                outlined
                dense
              >
              </v-autocomplete>
            </div>
          </input-group>
          <!-- end address -->
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import InputGroup from "@/own/components/fulfillment/skus/InputGroup.vue";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  // url,
  // numeric,
  // maxLength,
  // between,
  // minLength,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  components: { InputGroup },
  validations() {
    return {
      formData: {
        carrier_id: { required },
        mapping_type: { required },
        carrier_value: { required },
        country: {
          required: requiredIf(() => {
            return this.formData.mapping_type >= 0;
          }),
        },
        state: {
          required: requiredIf(() => {
            return this.formData.mapping_type >= 1;
          }),
        },
        city: {
          required: requiredIf(() => {
            return this.formData.mapping_type >= 2;
          }),
        },
        area: {
          required: requiredIf(() => {
            return this.formData.mapping_type >= 3;
          }),
        },
      },
    };
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: {},
    formData: {
      carrier_id: null,
      mapping_type: null,
      iq_value_id: null,
      carrier_value: null,
      country: null,
      state: null,
      city: null,
      area: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async onCountryChange(val, safe = false, is_id = false) {
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            if (!safe) {
              this.formData.state = null;
              this.formData.city = null;
              this.formData.area = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    async onStateChange(val, safe = false, is_id = false) {
      if (val) {
        let data = { state: null };
        if (is_id) {
          data.state = val;
        } else {
          let item = null;
          // try {
          if (typeof val === "object" && val !== null) {
            item = this.serverData.states.filter(
              (state) => state.id == val.id
            )[0];
          } else {
            item = this.serverData.states.filter((state) => state.id == val)[0];
          }
          // } catch {
          // return;
          // }
          if (!item) {
            return;
          }
          data.state = item.id;
        }
        this.pageLoader(true);
        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            if (!safe) {
              this.formData.city = null;
              this.formData.area = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    async onCityChange(val, safe = false, is_id = false) {
      if (val) {
        let data = { city: null };
        if (is_id) {
          data.city = val;
        } else {
          let item = null;
          // try {
          if (typeof val === "object" && val !== null) {
            item = this.serverData.cities.filter(
              (city) => city.id == val.id
            )[0];
          } else {
            item = this.serverData.cities.filter((city) => city.id == val)[0];
          }
          // } catch {
          // return;
          // }
          if (!item) {
            return;
          }

          data.city = item.id;
        }
        this.pageLoader(true);
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            if (!safe) {
              this.formData.area = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/shipping/courier_location_mappings/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
    convertToFormData() {
      let data = {
        carrier_id: null,
        mapping_type: 0,
        iq_value_id: null,
        carrier_value: null,
      };
      data.carrier_id = this.formData.carrier_id;
      data.mapping_type = this.formData.mapping_type;
      data.carrier_value = this.formData.carrier_value;

      if (this.formData.mapping_type == 0)
        data.iq_value_id = this.formData.country;
      else if (this.formData.mapping_type == 1)
        data.iq_value_id = this.formData.state;
      else if (this.formData.mapping_type == 2)
        data.iq_value_id = this.formData.city;
      else if (this.formData.mapping_type == 3)
        data.iq_value_id = this.formData.area;
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        ApiService.post("/shipping/courier_location_mappings/store", data)
          .then(() => {
            this.pageLoader(false);
            Swal.fire({
              title: "Created",
              text: "New courier location mapping has been added successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        carrier_id: null,
        mapping_type: 0,
        iq_value_id: null,
        carrier_value: null,
        country: null,
        state: null,
        city: null,
        area: null,
      };
    },
  },
  computed: {
    carrier_idErrors() {
      return this.handleFormValidation("carrier_id", this);
    },
    mapping_typeErrors() {
      return this.handleFormValidation("mapping_type", this);
    },
    carrier_valueErrors() {
      return this.handleFormValidation("carrier_value", this);
    },
    countryErrors() {
      return this.handleFormValidation("country", this);
    },
    stateErrors() {
      return this.handleFormValidation("state", this);
    },
    cityErrors() {
      return this.handleFormValidation("city", this);
    },
    areaErrors() {
      return this.handleFormValidation("area", this);
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !!this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !!this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !!this.serverData.areas;
    },
  },
};
</script>

<style scoped>
.editorStyle {
  max-height: 70vh;
  overflow: hidden;
}
</style>
